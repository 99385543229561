:root {
  --fuente-tokko: 'Nunito Sans';
  --alert: #f4cd00;
  --alertSubtle: #fff4bd;
  --black: #2f373d;
  --color-info-50: #e8f4fb;
  --color-info-100: #d1e9f6;
  --color-info-600: #1473a9;
  --color-info-700: #0f567f;
  --color-success-700: #0e5e4a;
  --color-success-800: #0a3e31;
  --color-primary-600: #b21112;
  --color-black-blanket-3: #1d23274d;
  --color-white-transparency-3: #ffffff26;
  --color-yellow-100: #fdf5d4;
  --color-yellow-200: #faeba9;
  --color-primary-400: #e54445;
  --color-black: #1d2327;
  --primary: #df1e02;
  --primaryHover: #fd2b0c;
  --primaryPressed: #fd4e34;
  --primaryAccent: #f6d4cf;
  --secondary: #1a4958;
  --secondary-50: #daebf0;
  --secondary-100: #a3ccda;
  --secondary-800: #0a1d23;
  --secondaryHover: #25697e;
  --secondaryPressed: #4f91a4;
  --subtle: #707e86;
  --subtleHover: #4d5b64;
  --subtlePressed: #384248;
  --success: #068b5b;
  --successDark: #00b775;
  --successDarker: #00734a;
  --error: #b21702;
  --backgroundError: #fff8f6;
  --backgroundError2: #fdddd8;
  --backgroundErrorRowTable: #ffede8;
  --borderRowTable: #ffe0dc;
  --backgroundAlert1: #eef4fe;
  --backgroundAlert2: #cee0fc;
  --backgroundCyan2: #ecf0fd;
  --backgroundSuccess: #d9fff1;
  --darkRedTokko: #a71601;
  --disabled: #c1ccd0;
  --lightBlue: #6a88f2;
  --info: #386df4;
  --info-500: #1b90d2;
  --infoAlert: #4268ef;
  --suportText: #1a48eb;
  --slateBlue: #485c66;
  --inputErrors: #fff2f0;
  --inputBackground: #f7f8fa;
  --inputPlaceholderColor: #a6b2ba;
  --darkGray: #6d777d;
  --grey-50: #FBFCFC;
  --grey-100: #F3F6F8;
  --grey-200: #EAEEF1;
  --grey-300: #D6DEE2;
  --grey-400: #C2CCD3;
  --grey-500: #94A2AB;
  --grey-600: #586A74;
  --grey-700: #44545D;
  --grey-800: #2E393F;
  --feedback: #048465;
  --tips: #ffd02a;
  --badgeBackgroundSuccess: #9ee4cb;
  --badgeSuccess: #048465;
  --deepGray: #1d2327;
  --mainButtonText: #49565d;
  --transparent: #00000000;
  --neutralGray1: #344149;
  --neutralGray2: #566a75;
  --neutralGray3: #6b7c85;
  --neutralGray4: #94a2ab;
  --neutralGray5: #c7d1d7;
  --neutralGray6: #dde5e9;
  --neutralGray7: #eaeef1;
  --neutralGray8: #f3f6f8;
  --neutralGrayLight: #eff3f5;
  --neutralNegro: #2f373d;
  --neutrals-Gris2: #566a75;
  --nightShadeLight: #1d232780;
  --white: #ffffff;
  --yellow: #fff502;
  --petrokoPressed: #3188a4;
  --propertyAvailable: #00b775;
  --propertyNotAvailable: #a71601;
  --propertyAppraise: #a71601;
  --propertyReserved: #e77000;
  --petrokoBackground2: #f2f8fa;
  --petrokoLighter: #cce8f0;
  --successAvailablePrincipal: #00d186;
  --petrokoHeader: #25697e;
  --pretrokoLight: #a2d4e5;
  --blue5: #1b90d2;
  --blue8: #a3d3ed;
  --blue10: #e8f4fb;
  --blue4: #1473a9;
  --petrokkoLighter: #cce8f0;
  --petrokko: #1a4958;
  --petrokkoPressed: #5aa5bb;
  --petrokkoLight: #a2d4e5;
  --petrokkoSecondary-700: #102c35;
}
